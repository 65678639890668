import axios from "axios"

import { TAppDispatch } from "../../../redux/store"
import { AddCookies, PrintLabel, TransferToDelivery, UpdateOrderStatus } from "../../../types/api"
import { getAccessToken } from "../../../utils/auth"
import { printWithQZTray } from "../../../utils/print"
import { showErrorMessage, showErrorNotification } from "../../../utils/ui"
import { axiosConfigAuth, handleResponseError } from "../middleware"
import {
  API_COLLECT_ORDERS,
  API_SBERMARKET_COOKIES,
  API_STOCK_FBS_ADD_IMPORT_TASK,
  API_STOCK_FBS_DOWNLOAD_EXCEL, API_STOCK_FBS_GET_CHECK_AND_CLOSE_SUPPLIER,
  API_STOCK_FBS_GET_IMPORT_RESULT,
  API_STOCK_FBS_GET_PARTNERS, API_STOCK_FBS_GET_SUPPLIERS_IMPORT_RESULT,
  API_STOCK_FBS_GET_TABLE_DATA,
  API_STOCK_FBS_LABEL_DOWNLOAD, API_STOCK_FBS_PRINT_LABEL, API_STOCK_FBS_PRINT_STICKER, API_STOCK_FBS_SADOVOD_TO_BUILD,
  API_STOCK_FBS_STICKER_DOWNLOAD,
  API_STOCK_FBS_STICKER_REMOVE,
  API_STOCK_FBS_STICKER_UPDATE,
  API_STOCK_FBS_SUPPLY_ADD,
  API_STOCK_FBS_SUPPLY_UPDATE, API_STOCK_FBS_TRANSFER_TO_DELIVERY, API_STOCK_FBS_UPDATE_ORDERS,
  API_STOCK_FBS_UPDATE_STATUS
} from "../urls"

export const stockFBSMarketApi = {
  fetchList: async (filters: any) => {
    return axios.post(API_STOCK_FBS_GET_TABLE_DATA, filters, axiosConfigAuth())
  },
  fetchImportResults: async () => {
    return axios.get(API_STOCK_FBS_GET_IMPORT_RESULT, axiosConfigAuth())
  },
  fetchInfoByBarcode: async (barcode:string) => {
    return axios.get(`${API_COLLECT_ORDERS}/${barcode}`, axiosConfigAuth())
  },
  fetchSuppliersImportResults: async () => {
    return axios.get(API_STOCK_FBS_GET_SUPPLIERS_IMPORT_RESULT, axiosConfigAuth())
  },
  fetchCompanies: async () => {
    return axios.get(API_STOCK_FBS_GET_PARTNERS, axiosConfigAuth())
  },
  checkAndCloseSupplier: async (supplier_id:number) => {
    return axios.get(`${API_STOCK_FBS_GET_CHECK_AND_CLOSE_SUPPLIER}/${supplier_id}`, axiosConfigAuth())
  },
  savododToBuild: async () => {
    return axios.get(`${API_STOCK_FBS_SADOVOD_TO_BUILD}`, axiosConfigAuth())
  },
  removeSticker: async (id:number) => {
    return axios.get(`${API_STOCK_FBS_STICKER_REMOVE}/${id}`, axiosConfigAuth())
  },
  downloadSticker: async (id:number,isDownload=true) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.open("GET", `${API_STOCK_FBS_STICKER_DOWNLOAD}/${id}/${getAccessToken()}`, true)
      xhr.responseType = "blob"

      xhr.onload = async function () {
        if (this.status === 200) {
          if(isDownload){
            const blob = new Blob([ this.response ], { type: "application/pdf" })
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement("a")
            a.href = url
            a.download = `sticker_${id}.pdf`
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            window.URL.revokeObjectURL(url)
            resolve(null)
          }else{
            try {
              const response = await printWithQZTray(this.response)
              if(response === "refresh"){
                // showErrorMessage("Проверьте подключение с принтером.")
                resolve(null)
              }
            }catch(err){
              showErrorMessage("Проверьте подключение с принтером.")
              reject()
            }
          }

        }
        if (this.status === 400) {
          const reader = new FileReader()
          reader.onload = function() {
            try {
              const response = JSON.parse(reader.result as string)
              showErrorNotification(response.message)
            } catch (e) {
              console.log("Error parsing JSON response: ", e)
            }
          }
          reader.readAsText(this.response)
        }
      }
      xhr.send()
    })
  },
  downloadLabel: async (id:number,isDownload=true) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.open("GET", `${API_STOCK_FBS_LABEL_DOWNLOAD}/${id}/${getAccessToken()}`, true)
      xhr.responseType = "blob"

      xhr.onload = async function () {
        if (this.status === 200) {
          if (isDownload) {
            const blob = new Blob([this.response], {type: "application/pdf"})
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement("a")
            a.href = url
            a.download = `sticker_${id}.pdf`
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            window.URL.revokeObjectURL(url)
            resolve(null)
          } else {
            try {
              const response = await printWithQZTray(this.response)
              if (response === "refresh") {
                // showErrorMessage("Проверьте подключение с принтером.")
                resolve(null)
              }
            } catch (err) {
              showErrorMessage("Проверьте подключение с принтером.")
            }
          }
        }
        if (this.status === 400) {
          const reader = new FileReader()
          reader.onload = function () {
            try {
              const response = JSON.parse(reader.result as string)
              showErrorNotification(response.message)
            } catch (e) {
              console.log("Error parsing JSON response: ", e)
              reject()
            }
          }
          reader.readAsText(this.response)
        }
      }
      xhr.send()
    })
  },
  downloadExcel: async (filters:any) => {
    try {
      const response = await axios.post(
        API_STOCK_FBS_DOWNLOAD_EXCEL,
        filters,
        {
          ...axiosConfigAuth(),
          responseType: "blob",
        }
      )

      const url = window.URL.createObjectURL(new Blob([ response.data ]))
      const link = document.createElement("a")
      link.href = url
      const contentDisposition = response.headers["content-disposition"]
      const now = new Date()
      const year = now.getFullYear()
      const month = String(now.getMonth() + 1).padStart(2, "0")
      const day = String(now.getDate()).padStart(2, "0")

      let fileName = `export_${year}_${month}_${day}.csv`

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1]
        }
      }
      link.setAttribute("download", fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error:any) {
      const dispatch = (() => {}) as TAppDispatch
      handleResponseError(dispatch,error)
      console.error("Error downloading Excel file:", error)
    }
  },
  updateStatus: async (data:UpdateOrderStatus) => {
    return axios.post(API_STOCK_FBS_UPDATE_STATUS,data,axiosConfigAuth())
  },
  transferToDelivery: async (data:TransferToDelivery) => {
    return axios.post(API_STOCK_FBS_TRANSFER_TO_DELIVERY,data,axiosConfigAuth())
  },
  printLabel: async (data: PrintLabel,isDownload=false) => {
    try {
      const response = await axios.post(
        API_STOCK_FBS_PRINT_LABEL,
        data,
        {
          ...axiosConfigAuth(),
          responseType: "blob",
        }
      )
      const pdfBlob = await response.data

      try {
        if(isDownload){
          const url = window.URL.createObjectURL(new Blob([ pdfBlob ], { type: "application/pdf" }))
          const link = document.createElement("a")
          link.href = url

          let fileName = "labels.pdf"
          const contentDisposition = response.headers["content-disposition"]
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
            if (fileNameMatch?.length === 2) {
              fileName = fileNameMatch[1]
            }
          }

          link.setAttribute("download", fileName)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }else{
          const responsePrint = await printWithQZTray(pdfBlob)
          if(responsePrint === "refresh"){
            const url = window.URL.createObjectURL(new Blob([ pdfBlob ], { type: "application/pdf" }))
            const link = document.createElement("a")
            link.href = url

            let fileName = "labels.pdf"
            const contentDisposition = response.headers["content-disposition"]
            if (contentDisposition) {
              const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
              if (fileNameMatch?.length === 2) {
                fileName = fileNameMatch[1]
              }
            }

            link.setAttribute("download", fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
        }
      } catch (error) {
        console.log(error)
      }
    } catch (error: any) {
      const dispatch = (() => {}) as TAppDispatch
      handleResponseError(dispatch, error)
      console.error("Error downloading file:", error)
    }
  },
  printSticker: async (data: PrintLabel,isDownload=false) => {
    try {
      const response = await axios.post(
        API_STOCK_FBS_PRINT_STICKER,
        data,
        {
          ...axiosConfigAuth(),
          responseType: "blob",
        }
      )

      const pdfBlob = await response.data

      try {
        if(isDownload){
          const url = window.URL.createObjectURL(new Blob([ pdfBlob ], { type: "application/pdf" }))
          const link = document.createElement("a")
          link.href = url
          const contentDisposition = response.headers["content-disposition"]
          let fileName = "stickers.pdf"

          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
            if (fileNameMatch?.length === 2) {
              fileName = fileNameMatch[1]
            }
          }
          link.setAttribute("download", fileName)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }else {
          const responsePrint = await printWithQZTray(pdfBlob)
          if (responsePrint === "refresh") {
            const url = window.URL.createObjectURL(new Blob([ pdfBlob ], { type: "application/pdf" }))
            const link = document.createElement("a")
            link.href = url
            const contentDisposition = response.headers["content-disposition"]
            let fileName = "stickers.pdf"

            if (contentDisposition) {
              const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
              if (fileNameMatch?.length === 2) {
                fileName = fileNameMatch[1]
              }
            }
            link.setAttribute("download", fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
        }
      } catch (error) {
        console.log(error)
      }
    } catch (error: any) {
      const dispatch = (() => {}) as TAppDispatch
      handleResponseError(dispatch, error)
      console.error("Error downloading file:", error)
    }
  },
  updateOrders: async (data:PrintLabel) => {
    return axios.post(API_STOCK_FBS_UPDATE_ORDERS,data,axiosConfigAuth())
  },
  updateSticker: async (data: any) => {
    return axios.post(API_STOCK_FBS_STICKER_UPDATE, data, axiosConfigAuth())
  },
  addCookies: async (data: AddCookies) => {
    return axios.post(API_SBERMARKET_COOKIES, data, axiosConfigAuth())
  },
  addSupply: async (data: any) => {
    return axios.post(API_STOCK_FBS_SUPPLY_ADD, data, axiosConfigAuth())
  },
  updateSupply: async (data: any) => {
    return axios.post(API_STOCK_FBS_SUPPLY_UPDATE, data, axiosConfigAuth())
  },

  addImportTask: async (data: {partner_ids:number[],type:number}) => {
    return axios.post(API_STOCK_FBS_ADD_IMPORT_TASK, data, axiosConfigAuth())
  },

  checkCookies: async () => {
    return axios.get(`${API_SBERMARKET_COOKIES}`, axiosConfigAuth())
  },
}
