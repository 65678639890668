import React, { useState, useEffect } from "react"

import { Select } from "antd"

const { Option } = Select

interface Option {
  id: number;
  name: string;
}

interface AutoWidthSelectProps {
  options: Option[];
  value?: number;
  showSearch?:boolean;
  onChange?: (value: number) => void;
  placeholder?: string;
  allowClear?: boolean;
  disabled?:boolean;
  style?: React.CSSProperties;
}

const AutoWidthSelect: React.FC<AutoWidthSelectProps> = ({
  options,
  disabled=false,
  showSearch=false,
  value,
  onChange,
  style = {},
  placeholder = "",
  allowClear = false,
}) => {
  const [ filteredOptions,setFilteredOptions ] = useState<Option[]>(options)
  const [ maxWidth, setMaxWidth ] = useState(0)
  const [ dropdownOpen, setDropdownOpen ] = useState(false)

  useEffect(() => {
    const span = document.createElement("span")
    document.body.appendChild(span)
    span.style.visibility = "hidden"
    span.style.whiteSpace = "nowrap"

    let max = 0
    options.forEach((item) => {
      span.innerText = item.name
      max = Math.max(max, span.offsetWidth)
    })

    document.body.removeChild(span)
    setMaxWidth(max)
    setFilteredOptions(options)
  }, [ options ])

  const handleSearch = (searchValue:string) => {
    const filteredOptions = options.filter((el)=>el.name.toLowerCase().includes(searchValue.toLowerCase()))
    setFilteredOptions(filteredOptions)
  }
  const renderOptions = (
    filteredOptions.map((option) => (
      <Option
        key={option.id}
        value={option.id}
        style={option.id === -1 ? { background: "#00a2e8", color:"white" } : {}}
      >
        {option.name}
      </Option>
    ))
  )

  return (
    <Select
      showSearch={showSearch}
      value={value}
      allowClear={allowClear}
      onChange={onChange}
      onSearch={handleSearch}
      filterOption={false}
      disabled={disabled}
      notFoundContent={renderOptions}
      placeholder={placeholder}
      style={{ ...(dropdownOpen && { width: maxWidth + 80 }), ...style }}
      onDropdownVisibleChange={(open) => setDropdownOpen(open)}
    >
      {renderOptions}
    </Select>
  )
}

export default AutoWidthSelect
