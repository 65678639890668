import React, { FC, useEffect, useMemo, useState } from "react"

import {
  CalendarOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  LoadingOutlined, MenuOutlined,
  PrinterOutlined,
  QuestionCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons"
import { Button, Collapse, Table, Tooltip, Popconfirm, Pagination, Space } from "antd"
import { ColumnsType } from "antd/es/table"
import BaseTable from "react-base-table"
import { useDispatch } from "react-redux"

import AddSupplier from "./components/AddSupplier"
import useStockFbsColumns from "./components/columns"
import FilterTable from "./components/Filter"
import SuppliersModal from "./components/SuppliersModal"
import { stockFBSMarketApi } from "../../common/api/endpoints/stockFBSMarketApi"
import { handleResponseError } from "../../common/api/middleware"
import { API_WS_URL } from "../../common/api/urls"
import Accordion from "../../common/components/accordion/Accordion"
import AdvancedDropdown from "../../common/components/dropdowns/AdvancedDropdown"
import ImportResultAlert from "../../common/components/import-result-alert/ImportResultAlert"
import MainLayout from "../../common/components/layouts/MainLayout"
import Loading from "../../common/components/loading/Loading"
import ColumnSettings from "../../common/components/modals/ColumnSettings"
import PageHead from "../../common/components/page-header/PageHeader"
import AutoWidthSelect from "../../common/components/selects/AutoWidthSelect"
import { defaultStockFBSFilters } from "../../common/constants/filters"
import { routeNames } from "../../common/constants/routes"
import { useActions } from "../../common/hooks/useActions"
import useTableWidth from "../../common/hooks/useTableWidth"
import { useTypedSelector } from "../../common/hooks/useTypedSelector"
import { projectIcons } from "../../common/icons/projectIcons"
import { TAppDispatch } from "../../redux/store"
import { OrderStatus, PrintLabel, TransferToDelivery, UpdateOrderStatus } from "../../types/api"
import { getAccessToken } from "../../utils/auth"
import { marketPlacePictureMap } from "../../utils/data"
import { showErrorMessage, showSuccessMessage } from "../../utils/ui"
import {normalizeColumns, rowClassName} from "../../utils/function";

const Sbermarket: FC = () => {
  const [ filters, setFilters ] = useState<any>(defaultStockFBSFilters)
  const { filters: filtersFromResponse } = useTypedSelector((state) => state.stockFBS)

  const [ isVisible, setIsVisible ] = useState(true)
  const [ isSuppliesVisible,setIsSuppliesVisible ] = useState(false)
  const [ isSocket, setIsSocket ] = useState(false)

  const { isLoading,
    isLoadingLists,
    isUpdate,
    itemData,
    pagesData,
    companies,
    importResults,
    suppliersResults
  } =
      useTypedSelector((state) => state.stockFBS)
  const {
    stockFBSGetList,
    stockFBSGetImportResults,
    stockFBSGetSuppliersImportResults,
    setStockFBSUpdate,
    stockFBSGetCompanies,
  } =
      useActions()
  const dispatch = useDispatch()
  const [ isManageColumnsVisible,setIsManageColumnsVisible ] = useState(false)
  const [ addSupplierState,setAddSupplierState ] = useState<{
    visible: boolean,
    id: null | number | undefined ,
    partner_id: null | number | undefined,
    name: string | undefined,
    marketplace_id:number | null | undefined
  }>({ visible:false,id:null,partner_id:null,name:"",marketplace_id:null })
  const [ checkSupplierState,setCheckSupplierState ] = useState<{
    visible: boolean,
    id: null | number | undefined ,
    msg: string
  }>({ visible:false,id:null,msg:"" })
  const [ partnersState,setPartnersState ] = useState<{
    values:number[],
    type:number}>({
      values:[],
      type:1
    })
  const handlePartnerIdsChange = (type:number,values:number[]) => {
    setPartnersState({ type,values })
  }
  const [ isActionLoading,setIsActionLoading ] = useState(false)
  const [ isTransferToDeliveryLoading,setIsTransferToDeliveryLoading ] = useState(false)
  const [ transferSuppliers,setTransferSuppliers ] = useState<number | null>(null)

  const tableWidth = useTableWidth()
  const checkAndCloseSupplier = async (supplier_id:number) => {
    try{
      const response = await stockFBSMarketApi.checkAndCloseSupplier(supplier_id)
      if(response.data === "Success"){
        window.open(`${routeNames.stockSuppliers}/${supplier_id}`, "_blank")
      } else{
        setCheckSupplierState({
          visible:true,
          id:supplier_id,
          msg:response?.data
        })
      }
    }catch(err:any){
      handleResponseError(dispatch,err)
    }
  }
  const sadovodToBuild = async () => {
    try{
      setIsActionLoading(true)
      const { data } = await stockFBSMarketApi.savododToBuild()
      showSuccessMessage(data)
    }catch(err:any){
      handleResponseError(dispatch,err)
    }finally{
      setIsActionLoading(false)
    }
  }


  const handleCreateNewSupplier = (record:{id?:number | null,order_id?:number | null,marketplace_id?:number | null,partner_id?:number | null}) => {
    setAddSupplierState({
      visible:true,
      id:record?.id,
      partner_id:record?.partner_id,
      marketplace_id:record?.marketplace_id,
      name:""
    })
  }

  const isMobile = window.innerWidth <= 768
  const handleAction = async (action: number | string,params?:{order_ids:number[],marketplace_id:number,selectedRowKeys:string[]}) => {
    try{
      setIsActionLoading(true)
      const values = {
        status_id:action as OrderStatus,
        marketplace_id:params?.marketplace_id || filters?.marketplace_id,
        order_ids:params?.order_ids || selectedRowKeys,
        ...(isNotSelected && !params?.order_ids && { filters })
      } as UpdateOrderStatus
      await stockFBSMarketApi.updateStatus(values)
      showSuccessMessage("Статус успешно обновлен")
      //setStockFBSUpdate(true)
    }catch (error:any) {
      const dispatch = (() => {}) as TAppDispatch
      handleResponseError(dispatch,error)
      console.log(error)
    }finally{
      setIsActionLoading(false)
    }
  }

  const [
    columns,
    setColumns,
    defaultColumns,
    sorting,
    selectedRowKeys,
    setSelectedRowKeys
  ] = useStockFbsColumns({ setAddSupplierState,filters,handleAction })

  const connectWebSocket = (attempt=1) => {
    const maxAttempts = 3
    const socket = new WebSocket(`${API_WS_URL}/?token=${getAccessToken()}`)

    socket.onmessage = (e) => {
      try {
        const updates = JSON.parse(e.data)
        if (updates) {
          if (updates?.["import-fbs-orders"]?.status === true) {
            stockFBSGetImportResults()
            stockFBSGetSuppliersImportResults()
            setIsSocket(true)
            setStockFBSUpdate(true)
          }
          if (updates?.["update-supplies"]?.status === true) {
            stockFBSGetSuppliersImportResults()
            setIsSocket(true)
          }
          if (updates?.["update-orders"]?.status === true) {
            setStockFBSUpdate(true)
            setIsSocket(true)
          }
        }
      } catch (e) {
        console.log(e)
      }
    }

    socket.onerror = () => {
      if (attempt < maxAttempts) {
        showErrorMessage(`Попытка ${attempt} для переподключения к сокетам...`)
        setTimeout(() => connectWebSocket(attempt + 1), 1500)
      } else {
        showErrorMessage("К сожалению подключение к сокетам не удалось, пожалуйста проверьте ваше интернет соеденение и перезагрузите страницу.")
      }
    }

    socket.onclose = () => {
      if (attempt < maxAttempts) {
        showErrorMessage(`Попытка ${attempt} для переподключения к сокетам...`)
        setTimeout(() => connectWebSocket(attempt + 1), 1500)
      } else {
        showErrorMessage("К сожалению подключение к сокетам не удалось, пожалуйста проверьте ваше интернет соеденение и перезагрузите страницу.")
      }
    }
  }

  useEffect(() => {
    stockFBSGetImportResults()
    stockFBSGetSuppliersImportResults()
    stockFBSGetCompanies()
    stockFBSGetList({
      page: 1,
      pageSize: 10,
      sorting: sorting,
      filters
    })
    connectWebSocket()
  }, [])

  useEffect(() => {
    if (isUpdate) {
      stockFBSGetList({
        page: pagesData.currentPage,
        pageSize: pagesData.pageSize,
        sorting: sorting,
        filters: filters
      },isSocket)
      setStockFBSUpdate(false)
    }
  }, [ isUpdate,isSocket ])

  useEffect(()=>{
    const isTimeSinceOrder = Object.keys(sorting)[0] === "time_since_order"
    const arrivalSorting = {
      "arrival_date":sorting[Object.keys(sorting)[0]]
    }
    stockFBSGetList({
      page: pagesData.currentPage,
      pageSize: pagesData.pageSize,
      sorting: isTimeSinceOrder ? arrivalSorting : sorting,
      filters: filters
    },isSocket)
  },[ sorting ])

  const handleImportOrder = async (event:React.MouseEventHandler<HTMLAnchorElement>) => {
    if((event as {target?:{className?:string}})?.target?.className !== "action"){
      return
    }
    if(partnersState.values.length === 0){
      showErrorMessage("Выберите компании для которых требуется импорт.")
      return
    }
    try{
      const data = {
        partner_ids:partnersState.values,
        type:partnersState.type
      }
      await stockFBSMarketApi.addImportTask(data)
      showSuccessMessage("Задача на импорт успешно добавлена.")
    }catch(err){
      console.log(err)
      const responseErrorMessage = (err as unknown as {response:{data:{message:string}}}).response?.data?.message
      showErrorMessage(responseErrorMessage || "Произошла ошибка при отправке заказов на импорт")
    }
  }

  const handleImportExcel = async () => {
    try{
      await stockFBSMarketApi.downloadExcel({
        page: pagesData.currentPage,
        pageSize: pagesData.pageSize,
        sorting: sorting,
        filters: filters
      })
    }catch(err){
      console.log(err)
      const responseErrorMessage = (err as unknown as {response:{data:{message:string}}}).response?.data?.message
      showErrorMessage(responseErrorMessage || "Произошла ошибка при скачивании файла.")
    }
  }

  const isImportBtnDisabled = () => {
    return !importResults?.status
  }

  const toggleVisibility = () => {
    setIsVisible(!isVisible)
  }

  const isNotSelected = selectedRowKeys?.length === 0
  const isNotSelectedStatus = !filters?.status_id
  const isDisabledToBuild = ![ 1,3 ].includes(filters?.status_id) || isNotSelectedStatus
  const isDisabledToCollected = ![ 1,2 ].includes(filters?.status_id) || isNotSelectedStatus
  const isDisabledToNew = [ 1,5,6,7,8,9 ].includes(filters?.status_id) || (filters?.status_id === 3 && filters?.not_collected) || isNotSelectedStatus
  const isTransferToDeliveryDisabled = ![ 1,2,3 ].includes(filters?.status_id) || !filters?.partner_id || isNotSelectedStatus || !transferSuppliers

  const handleIconAction = async (action: "printLabel" | "printSticker" | "updateOrders") => {
    try{
      setIsActionLoading(true)
      const values = {
        ...(!isNotSelected && { order_ids:selectedRowKeys }),
        ...(isNotSelected && { filters }),
        ...(isNotSelected && { sorting })
      } as PrintLabel
      await stockFBSMarketApi[action](values)
      action === "updateOrders" &&  showSuccessMessage("Статус успешно обновлен")
    }catch (error:any) {
      const dispatch = (() => {}) as TAppDispatch
      handleResponseError(dispatch,error)
      console.log(error)
    }finally{
      //setStockFBSUpdate(true)
      setIsActionLoading(false)
    }
  }

  const handleTransferToDelivery = async () => {
    try{
      setIsTransferToDeliveryLoading(true)
      const values = {
        supply_id :transferSuppliers,
        marketplace_id:filters?.marketplace_id,
        partner_id :filters?.partner_id,
        ids:selectedRowKeys.map((el)=>itemData.find((item)=>item?.order_id.toString() === el.toString())?.order_id)
      } as TransferToDelivery
      await stockFBSMarketApi.transferToDelivery(values)
      showSuccessMessage("Заказы успешно переведены в поставку.")
      setSelectedRowKeys([])
      //setStockFBSUpdate(true)
    }catch (error:any) {
      const dispatch = (() => {}) as TAppDispatch
      handleResponseError(dispatch,error)
      console.log(error)
    }finally{
      setIsTransferToDeliveryLoading(false)
    }
  }
  useEffect(() => {
    setSelectedRowKeys([])
  }, [ filters ])

  useEffect(() => {
    setTransferSuppliers(null)
  }, [ filtersFromResponse?.supplies_for_transfer ])

  const dynamicStyles = useMemo(() => {
    const styles: any = {}
    itemData.forEach((item: any) => {
      if (item.line_color) {
        const className = `row-color-${item.line_color?.replace("#", "")}`
        styles[className] = { backgroundColor: `#${item.line_color}` }
      }
    })
    return styles
  }, [ itemData ])

  const StockSuppliersColumns: ColumnsType<any> = [
    {
      title: "Маркетплейс",
      dataIndex: "marketplace_id",
      key: "marketplace_id",
      align:"center",
      sorter: (a, b) => a.marketplace_id - b.marketplace_id,
      ellipsis: {
        showTitle: false,
      },
      width: 80,
      render: (val) => (
        <img alt={"Маркетплейс"} style={{ borderRadius: "50%", width: "28px" }}
          src={marketPlacePictureMap[val]}/>
      ),
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      ellipsis: {
        showTitle: false,
      },
      width: 80,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      ),
    },
    {
      title: "Внешний номер",
      dataIndex: "external_id",
      key: "external_id",
      sorter: (a, b) => a.external_id.localeCompare(b.external_id),
      ellipsis: {
        showTitle: false,
      },
      width: 90,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val && <strong style={{ background:"#f3f6fa",padding:"8px" }}>
            {val}
          </strong> }
        </Tooltip>
      ),
    },
    {
      title: "Организация",
      dataIndex: "org",
      key: "org",
      sorter: (a, b) => a.org.localeCompare(b.org),
      ellipsis: {
        showTitle: false
      },
      width: 150,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Дата",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      ellipsis: {
        showTitle: false,
      },
      width: 120,
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          <CalendarOutlined style={{ marginRight: "5px" }} />
          {val}
        </Tooltip>
      ),
    },
    {
      title: "Действия",
      key: "operation",
      width: 100,
      render: (val, record) => {
        return <Tooltip placement="topLeft" title={"Проверить и закрыть"}>
          <div style={{ display:"flex",justifyContent:"center",alignItems:"center" }}>
            <span onClick={()=>checkAndCloseSupplier(record.id)} style={{ color:"rgb(64, 150, 255)",cursor:"pointer" }}>Сохранить закрыть</span>
          </div>
        </Tooltip>
      },
    }
  ]

  const handleResetColumns = () => {
    setColumns(defaultColumns)
    localStorage.setItem("savedDropSupplierCols",JSON.stringify(normalizeColumns(defaultColumns)))
    showSuccessMessage("Порядок успешно возвращен.")
  }

  const handlePagination = (page:number,pageSize:number) => {
    stockFBSGetList({
      page,
      pageSize,
      sorting,
      filters
    },isSocket)
  }

  return (
    <>
      <style>
        {Object.keys(dynamicStyles).map(className => {
          const style = dynamicStyles[className]
          return `
            .${className} {
              background-color: ${style.backgroundColor};
            }
          `
        })}
      </style>
      <MainLayout pageTitle="Заказы на отгрузку">

        <div className="page-wrapper" style={{ ...(isMobile ? { width:"100vw" } : {}) }}>
          <PageHead
            titleStyles={{ fontWeight: "600" }}
            title="Заказы на отгрузку"
            extra={
              <div>
              </div>
            }
          />
          <div style={{ display: "flex", columnGap: "5px", marginBottom: "16px", marginLeft: "24px" }}>
            <Button
              type="primary"
              className={"blueButton"}
              onClick={(handleImportOrder as unknown as (React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement>))}
              disabled={isImportBtnDisabled()}
              loading={isLoading}
              style={{ paddingRight: 0,
                background:"#00a2e8"
              }}
            >
              <span className={"action"}>
                {projectIcons.sberImportIcon({ style: { marginRight: "5px" } })}
                ИМПОРТ ЗАКАЗОВ
              </span>

              <span style={{
                position: "absolute",
                marginTop: "-5px",
                marginLeft: "5px",
                marginRight: "5px",
                height: "100%"
              }}>
              </span>
              <span style={{ width: "6px" }}></span>
              <AdvancedDropdown
                items={companies}
                selectedIds={partnersState.values}
                setSelectedIds={(values) => {
                  handlePartnerIdsChange(1, values)
                }}
              />
            </Button>
            <Button
              type="primary"
              onClick={(handleImportOrder as unknown as (React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement>))}
              disabled={isImportBtnDisabled()}
              loading={isLoading}
              style={{ paddingRight: 0 , background:"#00a2e8" }}
              className={"blueButton"}
            >
              <span className={"action"}>
                {projectIcons.sberImportIcon({ style: { marginRight: "5px" } })}
                ИМПОРТ С ОБНОВЛЕНИЕМ ОСТАТКОВ
              </span>
              <span style={{
                position: "absolute",
                marginTop: "-5px",
                marginLeft: "5px",
                marginRight: "5px",
                height: "100%"
              }}>
              </span>
              <span style={{ width: "6px" }}></span>
              <AdvancedDropdown
                items={companies}
                selectedIds={partnersState.values}
                setSelectedIds={(values) => {
                  handlePartnerIdsChange(2, values)
                }}
              />
            </Button>
            <Button
              type="primary"
              onClick={(handleImportOrder as unknown as (React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement>))}
              disabled={isImportBtnDisabled()}
              loading={isLoading}
              style={{ paddingRight: 0 , background:"#00a2e8" }}
              className={"blueButton"}
            >
              <span className={"action"}>
                {projectIcons.sberImportIcon({ style: { marginRight: "5px" } })}
                ПЕРЕЗАГРУЗИТЬ ЗАКАЗЫ ЗА НЕДЕЛЮ
              </span>
              <span style={{
                position: "absolute",
                marginTop: "-5px",
                marginLeft: "5px",
                marginRight: "5px",
                height: "100%"
              }}>
              </span>
              <span style={{ width: "6px" }}></span>
              <AdvancedDropdown
                items={companies}
                selectedIds={partnersState.values}
                setSelectedIds={(values) => {
                  handlePartnerIdsChange(3, values)
                }}
              />
            </Button>
          </div>
          <Accordion title={"Результаты импорта"} isVisible={isVisible} toggleVisibility={toggleVisibility}>
            {importResults?.items?.map((result) => (
              <ImportResultAlert
                key={result.marketplace_id}
                marketplace_id={result.marketplace_id}
                message={result.msg}
                color={result.color}
              />
            ))}
          </Accordion>
          <Accordion title={"Поставки"} isVisible={isSuppliesVisible} toggleVisibility={()=>setIsSuppliesVisible(!isSuppliesVisible)}>
            <Table
              virtual={true}
              columns={StockSuppliersColumns}
              dataSource={suppliersResults}
              loading={{
                spinning: isLoading,
                indicator: <LoadingOutlined style={{ fontSize: 24 }} spin/>
              }}
              rowKey={"id"}
              size="small"
              bordered
            />
          </Accordion>
          <div className="page-content" style={{ paddingTop: 0 }}>
            <Collapse>
              <Collapse.Panel header="Фильтр заказов" key="1">
                <FilterTable
                  isLoading={isLoadingLists || isLoading}
                  setFilters={setFilters}
                  sorting={sorting}
                />
              </Collapse.Panel>
            </Collapse>

            <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
              <Popconfirm
                title={<span>Подтверждаете изменение статуса?<br/> Статус сменится у всех заказов <br/> согласно выбранным параметрам фильтра.</span>}
                onConfirm={() => handleAction(1)}
                placement={"bottom"}
                disabled={selectedRowKeys?.length !== 0}
              >
                <Tooltip
                  title="Переводит все отмеченные заказы в НОВЫЕ. В Новые можно вернуть, только заказы со статусами НА СБОРКЕ или СОБРАН">
                  <Button
                    disabled={isDisabledToNew}
                    onClick={selectedRowKeys?.length !== 0 ? () => handleAction(1) : undefined}
                    style={{
                      ...buttonStyle,
                      borderRadius: "4px 0 0 4px", ...(isDisabledToNew && { backgroundColor: "gray" })
                    }}
                  >
                    НОВЫЙ
                  </Button>
                </Tooltip>
              </Popconfirm>

              <Popconfirm
                title={<span>Подтверждаете изменение статуса?<br/> Статус сменится у всех заказов <br/> согласно выбранным параметрам фильтра.</span>}
                onConfirm={() => handleAction(2)}
                placement={"bottom"}
                disabled={selectedRowKeys?.length !== 0}
              >
                <Tooltip
                  title="Переводит все отмеченные заказы НА СБОРКУ. На сборку можно перевести, только заказы со статусами НОВЫЙ или СОБРАН">
                  <Button
                    disabled={isDisabledToBuild}
                    style={{
                      ...buttonStyle,
                      borderRadius: 0, ...(isDisabledToBuild && { backgroundColor: "gray" })
                    }}
                    onClick={selectedRowKeys?.length !== 0 ? () => handleAction(2) : undefined}
                  >
                    НА СБОРКЕ
                  </Button>
                </Tooltip>
              </Popconfirm>
              <Popconfirm
                title={<span>Подтверждаете изменение статуса?<br/> Статус сменится у всех заказов <br/> согласно выбранным параметрам фильтра.</span>}
                onConfirm={() => handleAction(3)}
                placement={"bottom"}
                disabled={selectedRowKeys?.length !== 0}
              >
                <Tooltip
                  title="Переводит все отмеченные заказы в СОБРАН. В собран можно перевести, только заказы со статусами НОВЫЙ или НА СБОРКЕ">
                  <Button
                    disabled={isDisabledToCollected}
                    onClick={selectedRowKeys?.length !== 0 ? () => handleAction(3) : undefined}
                    style={{ ...buttonStyle, borderRadius: 0, ...(isDisabledToCollected && { backgroundColor: "gray" }) }}
                  >
                    СОБРАН
                  </Button>
                </Tooltip>
              </Popconfirm>
              <Tooltip title="Печать стикеров для всех выбранных заказов">
                <Button
                  disabled={isActionLoading}
                  style={{
                    ...iconButtonStyle,
                    borderRadius: 0,
                    backgroundColor: isActionLoading ? "gray" : "#00a2e8",
                  }}
                  onClick={() => handleIconAction("printSticker")}
                  icon={<PrinterOutlined/>}
                />
              </Tooltip>
              <Tooltip title="Печать этикеток для всех выбранных заказов">
                <Button
                  disabled={isActionLoading}
                  onClick={() => handleIconAction("printLabel")}
                  style={{
                    ...iconButtonStyle,
                    borderRadius: 0,
                    backgroundColor: isActionLoading ? "gray" : "#00a2e8",
                  }}
                  icon={<FilePdfOutlined/>}
                />
              </Tooltip>
              <Tooltip title="Обновить статусы и стикеры заказов, там где их нет">
                <Button
                  disabled={isActionLoading}
                  style={{
                    ...iconButtonStyle,
                    borderRadius: "0 4px 4px 0",
                    borderLeft: "none",
                    backgroundColor: isActionLoading ? "gray" : "#00a2e8"
                  }}
                  onClick={() => handleIconAction("updateOrders")}
                  icon={<ReloadOutlined/>}
                />
              </Tooltip>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div>
                <span style={{ fontWeight: 600, fontSize: 16 }}>ПЕРЕНЕСТИ В ПОСТАВКУ  <Tooltip
                  placement="bottom"
                  title={
                    <div
                      className="page-title-tooltip-text"
                      dangerouslySetInnerHTML={{ __html: sendToSuppliesText }}
                    ></div>
                  }
                  color="#fff"
                  zIndex={9999}
                  trigger="click"
                  overlayClassName="page-title-tooltip"
                >
                  <QuestionCircleOutlined style={{ color: "#ccc", fontSize: 14 }}/>
                </Tooltip></span>
                <div style={{ display: "flex", columnGap: "16px", alignItems: "center", marginTop: "10px" }}>
                  <AutoWidthSelect
                    value={transferSuppliers as unknown as undefined}
                    options={[ ...(filtersFromResponse?.supplies_for_transfer || []), {
                      id: -1,
                      name: "Создать новую",
                    } ]}
                    placeholder={"Выберите поставку"}
                    style={{
                      minWidth: "180px",
                    }}
                    onChange={(value) => {
                      if (value === -1) {
                        const values = {
                          id: null,
                          partner_id: filters?.partner_id,
                          marketplace_id: filters?.marketplace_id
                        }
                        if (!filters?.partner_id) {
                          showErrorMessage("Выберите партнера, чтобы продолжить.")
                          setTransferSuppliers(null)
                          return
                        }
                        handleCreateNewSupplier(values)
                        setTransferSuppliers(null)
                        return
                      } else {
                        setTransferSuppliers(value)
                      }
                    }}
                  />
                  <Tooltip
                    placement="bottom"
                    title={
                      <div
                        className="page-title-tooltip-text"
                        dangerouslySetInnerHTML={{ __html: "Выберете обязательные фильтры: организация, маркетплейс, статус НОВЫЙ, НА СБОРКЕ или СОБРАН." }}
                      ></div>
                    }
                    color="#fff"
                    zIndex={9999}
                    trigger="hover"
                    overlayClassName="page-title-tooltip"
                  >
                    <Button
                      style={{
                        ...iconButtonStyle,
                        width: "unset",
                        height: "unset",
                        ...((isTransferToDeliveryDisabled || isTransferToDeliveryLoading) && { backgroundColor: "gray" })
                      }}
                      type={"primary"}
                      disabled={isTransferToDeliveryDisabled || isTransferToDeliveryLoading}
                      onClick={handleTransferToDelivery}>Перенести</Button>
                  </Tooltip>
                </div>
              </div>
              <Space>
                <Button
                  type="primary"
                  disabled={isActionLoading}
                  loading={isActionLoading}
                  onClick={sadovodToBuild}
                  className={"blueButton"}
                  style={{
                    height: "40px",
                    fontSize:15,
                    ...((isActionLoading) && { backgroundColor: "gray" })
                  }}
                >
                  Садовод на сборку
                </Button>
                <Tooltip title="Выберите заказы для экспорта.">
                  <Button
                    type="primary"
                    icon={<FileExcelOutlined/>}
                    onClick={handleImportExcel}
                    style={{
                      backgroundColor: "#28a745",
                      borderColor: "#28a745",
                      borderRadius: "4px",
                      color: "#fff",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    СКАЧАТЬ В EXCEL
                  </Button>
                </Tooltip>
              </Space>
            </div>
            <div className="universal-table-settings" style={{ width: tableWidth }}>
              <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginBottom: "10px" }}>
                <Button
                  style={{ border: "none", fontWeight: "400", color: "#4096ff" }}
                  onClick={() => setIsManageColumnsVisible(true)}
                >
                  <MenuOutlined
                    style={{ transform: "rotate(90deg)", fontSize: "16px", color: "#4096ff" }}
                  />
                  СТОЛБЦЫ
                </Button>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    style={{ border: "none", fontWeight: "400", color: "#4096ff" }}
                    onClick={handleResetColumns}
                  >
                    ВЕРНУТЬ ПОРЯДОК СТОЛБЦОВ
                  </Button>
                </div>
              </div>
              <Loading loading={isLoading}>
                <BaseTable
                  columns={columns as any}
                  data={itemData}
                  loading={isLoading}
                  fixed
                  width={tableWidth}
                  height={600}
                  disabled={isLoading}
                  rowKey="order_id"
                  rowHeight={150}
                  rowClassName={rowClassName}
                  onEndReachedThreshold={300}
                />
              </Loading>
            </div>
            <Pagination
              disabled={isLoading}
              style={{ width: isMobile ? "100vw" : "100%", marginBottom: "40px" }}
              onChange={handlePagination}
              size={"small"}
              current={pagesData.currentPage}
              showSizeChanger={true}
              pageSizeOptions={[ "10","50", "100", "200", "500","1000" ]}
              defaultCurrent={1}
              defaultPageSize={10}
              total={pagesData.total}
              showTotal={(total) => `Всего строк: ${total}`}
            />
          </div>
          {
            isManageColumnsVisible &&
              <ColumnSettings setVisible={setIsManageColumnsVisible}
                defaultColumns={defaultColumns}
                columns={columns}
                localStorageKey={"savedFBSColumns"}
                setColumns={setColumns}/>
          }
          {
            addSupplierState.visible && <AddSupplier addState={addSupplierState} setAddState={setAddSupplierState}/>
          }
          {
            checkSupplierState.visible &&
              <SuppliersModal state={checkSupplierState} setState={setCheckSupplierState}/>
          }
        </div>
      </MainLayout>
    </>
  )
}

const buttonStyle = {
  backgroundColor: "#00a2e8",
  color: "#fff",
  borderRadius: "4px",
  border: "none",
  fontSize: "14px",
  fontWeight: "400",
  height: "40px",
  padding: "0 16px",
}

const iconButtonStyle = {
  backgroundColor: "#00a2e8",
  color: "#fff",
  borderRadius: "4px",
  border: "none",
  fontSize: "14px",
  fontWeight: "bold",
  height: "40px",
  width: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}

const sendToSuppliesText = "Выберете заказы, которые хотите массово добавить в поставку на маркетплейсе. Сменить поставку можно только для заказов со статусами НОВЫЙ, НА СБОРКЕ или СОБРАН."


export default Sbermarket
