import React, { FC, useState } from "react"

import { BellOutlined, SendOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons"
import { Alert, Avatar, Button, Dropdown, Layout, Menu, Modal, Space } from "antd"
import { useNavigate } from "react-router-dom"

import styles from "./Header.module.scss"
import { showSuccessMessage } from "../../../utils/ui"
import {ADMIN_ROLE, SENIOR_MANAGER_ROLE, GENERAL_MANAGER, roles} from "../../constants/roles"
import { routeNames } from "../../constants/routes"
import { useActions } from "../../hooks/useActions"
import { useAllowedRoles } from "../../hooks/useAllowedRoles"
import { useTypedSelector } from "../../hooks/useTypedSelector"
import { projectIcons } from "../../icons/projectIcons"

const Header: FC = () => {
  const navigate = useNavigate()
  const isAdmin = useAllowedRoles([ ADMIN_ROLE ])
  const isManager = useAllowedRoles([ SENIOR_MANAGER_ROLE,roles.SENIOR_MANAGER_CANCELLED ])
  const isGeneralManager = useAllowedRoles([ GENERAL_MANAGER ])

  const { authLogout, connectUserTelegram,removeUserTelegram } = useActions()
  const { name, telegram,id } = useTypedSelector(
    (state) => state.currentUser
  )
  const [ isNotificationVisible,setIsNotificationVisible ] = useState(false)

  const [ tgLoading, setTgLoading ] = useState(false)

  const userMenuItems = [
    {
      key: "1",
      label: (
        <div onClick={() =>
          authLogout(() => {
            navigate(routeNames.login)
          })
        }>
          <span

          >
          Выход
          </span>
        </div>
      )
    }
  ]

  const settingsItems = [
    {
      key: "1",
      label: (
        <a style={{ display:"flex",columnGap:"5px",alignItems:"center" }} onClick={() => {navigate(routeNames.monitoring)}}>
          {projectIcons.monitoringIcon()}
          Мониторинг
        </a>
      )
    },
    {
      key: "3",
      label: (
        <a style={{ display:"flex",columnGap:"5px",alignItems:"center" }} onClick={() => {navigate(routeNames.imports)}}>
          {projectIcons.MdiImport()}
          Импорт
        </a>
      )
    },
    {
      key: "2",
      label: (
        <a style={{ display:"flex",columnGap:"5px",alignItems:"center" }} onClick={() => {navigate(routeNames.telegramNotifications)}}>
          <SendOutlined /> Уведомления
        </a>
      )
    },
  ]
  const telegramModalUI = () => {
    return (
      <Space>
        {telegram ? (
          <div style={{ display:"flex",flexDirection:"column",rowGap:"16px" }}>
            <Alert message="Телеграм подключен" type="success" showIcon />
            <Button type="primary" loading={tgLoading} onClick={() => {
              setTgLoading(true)
              removeUserTelegram(Number(id),() => {
                showSuccessMessage("Телеграм успешно отключен")
                setTgLoading(false)
              })
            }}>
              <SendOutlined /> Отключить телеграм
            </Button>
          </div>
        ) : (
          <div style={{ display:"flex",flexDirection:"column",rowGap:"16px" }}>
            <Alert message="Телеграм не подключен" type="info" showIcon />
            <Button type="primary" loading={tgLoading} onClick={() => {
              setTgLoading(true)
              connectUserTelegram()}}
            >
              <SendOutlined /> Подключить телеграм
            </Button>
          </div>
        )}
      </Space>
    )
  }

  return (
    <Layout.Header className={styles.headerContainer}>
      <Space direction={"horizontal"}>
        {
          (isAdmin || isManager || isGeneralManager) && (
            <Dropdown
              overlay={<Menu items={settingsItems}/>}
              placement="bottomLeft"
              trigger={[ "click" ]}
            >
              <Button
                type="text"
                icon={<SettingOutlined />}
              >
                Настройки
              </Button>
            </Dropdown>
          )
        }
        <div className={styles.headerItem} onClick={()=>setIsNotificationVisible(true)}>
          <BellOutlined /> Уведомления в Telegram
        </div>
      </Space>

      <Space direction="horizontal">

        <Dropdown
          menu={{
            items: userMenuItems
          }}
          placement="bottomCenter"
          trigger={[ "click" ]}
        >
          <div className={styles.userInfo}>
            <Avatar size="default" icon={<UserOutlined />} />
            <span>{name || "Пользователь"}</span>
          </div>
        </Dropdown>
      </Space>
      <Modal open={isNotificationVisible} onCancel={()=>setIsNotificationVisible(false)} footer={null} title={"Статус уведомлений"}>
        <div>
          <p>
            Бот для уведомлений по задачам.
          </p>
          {telegramModalUI()}
        </div>
      </Modal>
    </Layout.Header>
  )
}

export default Header
