// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import React from "react"

import { Button, Image, Space, Tooltip } from "antd"
import { Link } from "react-router-dom"

import { routeNames } from "../../../common/constants/routes"
import { marketPlacePictureMap, vk_image } from "../../../utils/data"

const DropshippingColumns = (props: { setIsAddOpen: (open: boolean) => void; setProductId: (id: number | null) => void }): any[] => [
  {
    key: "photo",
    dataKey: "photo",
    title: "Фото",
    width: 120,
    cellRenderer: ({ rowData }) => (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Image
          alt="Основное фото"
          style={{ width: "60px" }}
          src={rowData.photo || "https://brilliant24.ru/files/cat/template_01.png"}
        />
      </div>
    ),
    resizable:true,
  },
  {
    key: "quantity",
    dataKey: "quantity",
    title: "Количество",
    width: 150,
    cellRenderer: ({ rowData }) => (
      <Tooltip placement="topLeft" title={rowData.quantity}>
        {rowData.quantity}
      </Tooltip>
    ),
    resizable:true,
  },
  {
    key: "arrival_date",
    dataKey: "arrival_date",
    title: "Время с момента заказа",
    width: 150,
    cellRenderer: ({ rowData }) => (
      <Tooltip placement="topLeft" title={rowData.arrival_date.value}>
        <strong style={{ color: `#${rowData.arrival_date.color}` }}>{rowData.arrival_date.value}</strong>
      </Tooltip>
    ),
    resizable:true,
  },
  {
    key: "name",
    dataKey: "name",
    title: "Название",
    width: 300,
    cellRenderer: ({ rowData }) => (
      <div style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
        <Tooltip placement="topLeft" title={rowData.name}>
          {rowData.name}
        </Tooltip>
        <div style={{ marginTop: 8,display:"flex",columnGap:5,alignItems:"center",flexWrap:"wrap" }}>
          {rowData.suppliers && rowData.suppliers.length > 0 ? (
            <>
              {rowData.suppliers.map((supplier: any, index: number) => (
                <React.Fragment key={supplier.id}>
                  <Button
                    type="link"
                    size="small"
                    style={{ paddingLeft:0 }}
                  >
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={`${routeNames.dropshippingSupplier}/${supplier.id}`}
                    >
                      {supplier.name}{index < rowData.suppliers.length - 1 && ","}
                    </Link>

                  </Button>
                </React.Fragment>
              ))}
              <Button
                type="link"
                size="small"
                onClick={() => {
                  props.setProductId(rowData.product_id)
                  props.setIsAddOpen(true)
                }}
                style={{ paddingLeft:0 }}
              >
                    Выбрать поставщика
              </Button>
            </>
          ) : (
            <Button
              type="link"
              size="small"
              style={{ paddingLeft:0 }}
              onClick={() => {
                props.setProductId(rowData.product_id)
                props.setIsAddOpen(true)
              }}
            >
                  Выбрать поставщика
            </Button>
          )}
        </div>
      </div>
    ),
    resizable:true,
  },
  {
    key: "sku",
    dataKey: "sku",
    title: "Артикул",
    width: 225,
    cellRenderer: ({ rowData }) => (
      <Tooltip placement="topLeft" title={rowData.sku}>
        {rowData.sku}
      </Tooltip>
    ),
    resizable:true,
  },
  {
    key: "links",
    dataKey: "links",
    title: "Ссылки",
    width: 150,
    cellRenderer: ({ rowData }) => (
      <Space style={{ display: "flex", justifyContent: "center" }}>
        {rowData.links.product_url && (
          <a href={rowData.links.product_url} target="_blank" rel="noopener noreferrer">
            <img
              alt="Продукт"
              src={marketPlacePictureMap[rowData.links.marketplace_id]}
              style={{ borderRadius: "50%", width: "24px", marginRight: "8px", marginLeft: "8px" }}
            />
          </a>
        )}
        {rowData.links.vk_url && (
          <a href={rowData.links.vk_url} target="_blank" rel="noopener noreferrer">
            <img alt="VK" style={{ borderRadius: "50%", width: "24px" }} src={vk_image} />
          </a>
        )}
      </Space>
    ),
    resizable:true,
  },
  {
    key: "purchase_price",
    dataKey: "purchase_price",
    title: "Закупочная цена",
    width: 150,
    cellRenderer: ({ rowData }) => (
      <Tooltip placement="topLeft" title={rowData.purchase_price}>
        {rowData.purchase_price}
      </Tooltip>
    ),
    resizable:true,
  },
  {
    key: "barcode",
    dataKey: "barcode",
    title: "Штрих-код",
    width: 150,
    cellRenderer: ({ rowData }) => (
      <Tooltip placement="topLeft" title={rowData.barcode}>
        {rowData.barcode}
      </Tooltip>
    ),
    resizable:true,
  },
]

export default DropshippingColumns
